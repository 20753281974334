import React, { useEffect, useState, useContext, useRef } from "react";
import { navBarContext, authContext } from "./context/context";
import { updateLatestActions, addToSummary, pitchTracking, urlTracking } from "../utils";
import {
  StartupButton,
  Modal,
  TextButton,
  PrairiButton,
  MatchesReminder,
  Loading,
  CompleteYourProfile,
  Badge,
  FlagLink,
} from "@peterfosso/prairi-components";
import Logo from "../assets/prairiwhite.png";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Flag from "../assets/flag.png";
import ThankYou from "../assets/thankyou-unicorn.png";
import {
  collection,
  db,
  getDoc,
  doc,
  query,
  where,
  getDocs,
  updateDoc,
  Timestamp,
  addDoc,
} from "../firebase/firebaseApp";
import axios from "axios";
import Unicorn from "../assets/silver-unicorn.png";
import { usStates } from "../countries";

const Dashboard1 = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  const [interestedStyle, setInterestedStyle] = useState({
    borderColor: "#92d050",
    color: "white",
  });
  const [archivedStyle, setArchivedStyle] = useState({
    borderColor: "#a5a5a5",
    color: "#a5a5a5",
  });
  const [interestedStartups, setInterestedStartups] = useState([]);
  const [archivedStartups, setArchivedStartups] = useState([]);
  const [pendingStartups, setPendingStartups] = useState([]);
  const [interestedStartupsData, setInterestedStartupsData] = useState([]);
  const [archivedStartupsData, setArchivedStartupsData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [showInterested, setShowInterested] = useState(true);
  const [showArchived, setShowArchived] = useState(false);
  const [currentUserData, setCurrentUserData] = useState({});
  const [currentUserId, setCurrentUserId] = useState("");
  const [notInterestedReasons, setNotInterestedReasons] = useState("");
  const [interestedPopUp, setInterestedPopUp] = useState(false);
  const [notInterestedPopUp, setNotInterestedPopUp] = useState(false);
  const [userRef, setUserRef] = useState({});
  const [archivedPopUp, setArchivedPopUp] = useState(false);
  const [notInterestedPopUpFromArchived, setNotInterestedPopUpFromArchived] =
    useState(false);
  const [textReason, setTextReason] = useState("");
  const [flagReason, setFlagReason] = useState(null);
  const [thankYouPopUp, setThankYouPopUp] = useState(false);
  const [flagFromInterestedPopUp, setFlagFromInterestedPopUp] = useState(false);
  const [flagFromArchivedPopUp, setFlagFromArchivedPopUp] = useState(false);
  const [potentialName, setPotentialName] = useState("");
  const [potentialEmail, setPotentialEmail] = useState("");
  const [potentialPhone, setPotentialPhone] = useState("");
  const [potentialCode, setPotentialCode] = useState("");
  const [potentialId, setPotentialId] = useState("");
  const [shareError, setShareError] = useState("")
  const [tySharePopup, setTySharePopup] = useState(false);
  const time = Timestamp.fromDate(new Date());
  const latestTime = time
    .toDate()
    .toLocaleDateString("en-US", { month: "2-digit", day: "2-digit" });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const containerRef = useRef(null);
  let navigate = useNavigate();
  // const [welcomePopup, setWelcomePopup] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (potentialPhone!=="" || potentialEmail!==""){
      setShareError(false)
    }
  }, [potentialEmail, potentialPhone])




  const getDataFromUser = async (uid) => {
    //pass currentUser.uid when the moment comes
    try {
      const q = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      // console.log("userData", JSON.stringify(userData))
      setCurrentUserData(userData);
      setCurrentUserId(userId);
      let ref = doc(db, "users", userId);
      setUserRef(ref);
      // console.log("currentUserData inside getDataFromUser", currentUserData)
    } catch (error) {
      //console.log(error);
    }
  };

  const handleNotInterestedReason = (event) => {
    const selectedValue = event.target.value;
    if (event.target.checked) {
      if (selectedValue !== "other") {
        setNotInterestedReasons([...notInterestedReasons, selectedValue]);
      }
    } else {
      setNotInterestedReasons(
        notInterestedReasons.filter((value) => value !== selectedValue)
      );
    }
  };

  const handleFlagReason = (event) => {
    const selectedValue = event.target.value;
    setFlagReason(selectedValue);
  };

  const handleNotInterestedReasonText = (event) => {
    const checkbox = document.getElementById("other");
    const checkbox2 = document.getElementById("other-reason");
    checkbox.checked = true;
    checkbox2.checked = true;
    setTextReason(event.target.value);
  };

  const fetchInterestedById = async () => {
    try {
      if (currentUserData && currentUserData.interestedMatches) {
        const interestedIds = currentUserData.interestedMatches;

        // Use Promise.all to fetch all documents concurrently
        const fetchPromises = interestedIds.map(async (userId) => {
          const docRef = doc(db, "users", userId);
          const docSnap = await getDoc(docRef);
          return docSnap.data();
        });

        // Wait for all fetchPromises to complete
        const userDatas = await Promise.all(fetchPromises);

        // Now userDatas contains the data for each user
        const matchingUsers = userDatas.map((userData, index) => ({
          ...userData,
          id: interestedIds[index], // assuming interestedIds is an array of user IDs
        }));

        //console.log("Users with matching IDs:", matchingUsers);
        //console.log("interestedStartupsData after set in fetchUsersById", JSON.stringify(interestedStartupsData));
        setInterestedStartupsData(matchingUsers.reverse());
      }
    } catch (error) {
      console.error("Error fetching users by ID:", error);
    }
  };

  const fetchArchivedById = async () => {
    try {
      if (currentUserData && currentUserData.archivedMatches) {
        const archivedIds = currentUserData.archivedMatches;

        // Use Promise.all to fetch all documents concurrently
        const fetchPromises = archivedIds.map(async (userId) => {
          const docRef = doc(db, "users", userId);
          const docSnap = await getDoc(docRef);
          return docSnap.data();
        });

        // Wait for all fetchPromises to complete
        const userDatas = await Promise.all(fetchPromises);

        // Now userDatas contains the data for each user
        const matchingUsers = userDatas.map((userData, index) => ({
          ...userData,
          id: archivedIds[index], // assuming interestedIds is an array of user IDs
        }));

        //("Users with matching IDs:", matchingUsers);
        //console.log("archivedStartupsData after set in fetchUsersById", JSON.stringify(archivedStartupsData));
        setArchivedStartupsData(matchingUsers.reverse());
      }
    } catch (error) {
      console.error("Error fetching users by ID:", error);
    }
  };

  const fetchData = async () => {
    try {
      if (currentUser) {
        // Assuming currentUser is defined in your component
        await getDataFromUser(currentUser.uid); // Pass the UID from currentUser
        // console.log("interestedStartupData inside fetchData", interestedStartupsData)
        // console.log("archivedStartupData inside fetchData", archivedStartupsData)
        setDataFetched(true);
      }
    } catch (error) {
      //console.log('error fetching data');
    }
  };

  useEffect(() => {
    fetchData();
    if (!currentUser) {
      navigate("/login");
    }
  }, [currentUser]);


  useEffect(() => {
    if (currentUserData.category === "investor") {
      fetchInterestedById();
      fetchArchivedById();
      if (
        !currentUserData.agreed &&
        currentUserData.country !== "United States"
      ) {
        //(JSON.stringify(currentUserData))
        navigate("/investorwelcome");
      }

      //console.log(JSON.stringify(currentUserData))
    }
    if (currentUserData.category === "startup") {
      navigate("/startupdashboard");
    }
  }, [currentUserData]);

  const seeNotifications = async () => {
    //console.log("see notif")
    await updateLatestActions(userRef, "Clicked Matches notification banner", isMobile)
    navigate(`/notifications/${currentUserId}`);
  };

  useEffect(() => {
    // When currentUserData changes, update the state variables
    if (currentUserData) {
      if (currentUserData.interestedMatches) {
        setInterestedStartups(currentUserData.interestedMatches);
        // console.log("interested inside useEffect", currentUserData.interestedMatches)
      }
      if (currentUserData.archivedMatches) {
        setArchivedStartups(currentUserData.archivedMatches);
        // console.log("archived inside useEffect", currentUserData.archivedMatches)
      }
      if (currentUserData.pendingMatches) {
        setPendingStartups(currentUserData.pendingMatches);

      }
      // console.log("interested data", interestedStartupsData)
    }
  }, [currentUserData, currentUserId]);

  const [AdminOnline, setAdminOnline] = useState(false);
  const handleAdmin = () => {
    setAdminOnline(true);
    navigate("/admindashboard");
  };

  const removeInterestedMatch = async (userId, matchId) => {
    try {
      // Fetch the current user's document
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        //
        // Get the current "pendingMatches" array
        const interestedMatches = userDoc.data().interestedMatches;

        // Find and remove the match from the array
        const updatedMatches = interestedMatches.filter(
          (match) => match !== matchId
        );
        // Update the user's document with the modified "pendingMatches" array
        await updateDoc(userRef, { interestedMatches: updatedMatches });
        setInterestedStartups(updatedMatches);

        //console.log(`Match ${matchId} removed from pendingMatches`);
      } else {
        //  console.log("User document not found");
      }
    } catch (error) {
      // console.error("Error removing match:", error);
    }
  };

  const removeArchivedMatch = async (userId, matchId) => {
    try {
      // Fetch the current user's document
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      // console.log("userId and matchId inside removeArchived", userId, matchId)
      if (userDoc.exists()) {
        //
        // Get the current "pendingMatches" array
        const archivedMatches = userDoc.data().archivedMatches;

        // Find and remove the match from the array
        const updatedMatches = archivedMatches.filter(
          (match) => match !== matchId
        );
        // Update the user's document with the modified "pendingMatches" array
        await updateDoc(userRef, { archivedMatches: updatedMatches });
        setArchivedStartups(updatedMatches);

        //console.log(`Match ${matchId} removed from pendingMatches`);
      } else {
        //  console.log("User document not found");
      }
    } catch (error) {
      // console.error("Error removing match:", error);
    }
  };

  const addInterestedMatch = async (userId, matchId) => {
    try {
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      if (userDoc) {
        let updatedMatches;
        const userInterestedMatches = userDoc.data().interestedMatches || [];
        const startupToAdd = matchId;
        if (!userInterestedMatches.includes(matchId)) {
          updatedMatches = [...userInterestedMatches, startupToAdd];
        }
        await updateDoc(userRef, { interestedMatches: updatedMatches });

        // console.log(`Match ${matchId} added to interested`);
      }
    } catch (error) {
      //console.error("Error removing match:", error);
    }
  }; //

  const addArchivedMatch = async (userId, matchId) => {
    try {
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      //Adding startup so we can track archived for them as well
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      if (userDoc) {
        let updatedMatches;
        let updatedStartupArchived;
        const userArchivedMatches = userDoc.data().archivedMatches || [];
        const startupArchivedMatches = startupDoc.data().archivedMatches || [];
        const investorToAdd = userId;
        const startupToAdd = matchId;
        if (!userArchivedMatches.includes(matchId)) {
          updatedMatches = [...userArchivedMatches, startupToAdd];
        }
        if (!startupArchivedMatches.includes(userId)) {
          updatedStartupArchived = [...startupArchivedMatches, investorToAdd];
        }
        await updateDoc(userRef, { archivedMatches: updatedMatches });
        updateLatestActions(
          userRef,
          "Confirmed Archived click from dashboard",
          isMobile
        );
        await updateDoc(startupRef, {
          archivedMatches: updatedStartupArchived,
        });
        // console.log(`Match ${matchId} added to archived`);
      }
    } catch (error) {
      // console.error("Error archivinf match:", error);
    }
  }; //

  const addNotInterestedMatch = async (userId, matchId) => {
    try {
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      console.log("Entered addNotInterestedMatch")
      if (userDoc) {
        let updatedMatches;
        let updatedStartupNot;
        let updatedInvestorInterests; //technically belong to startup
        let updatedInvestorContacts; //technically belong to startup
        let updatedArchivedStartup; 
        //gotta delete the investor from startup document
        const userNotInterestedMatches =
          userDoc.data().notInterestedMatches || [];
        const startupNotInterestedMatches =
          startupDoc.data().notInterestedMatches || [];
          const startupInvestorInterests =
        startupDoc.data().investorInterests || [];
        const startupInvestorContacts =
        startupDoc.data().investorContacts || [];
        const startupArchivedMatches =
        startupDoc.data().archivedMatches || [];
        console.log(startupInvestorInterests)
        const startupToAdd = matchId;
        const investorToAdd = userId;
        if (startupInvestorInterests.includes(userId)){
          updatedInvestorInterests = startupInvestorInterests.filter((match) => match !== userId)
          console.log(`updated: ${updatedInvestorInterests}`)
        }
        if (startupInvestorContacts.includes(userId)){
          updatedInvestorContacts = startupInvestorContacts.filter((match) => match !== userId)
        }
        if (startupArchivedMatches.includes(userId)){
          updatedArchivedStartup = startupArchivedMatches.filter((match) => match !== userId)
        }
        if (!userNotInterestedMatches.includes(matchId)) {
          updatedMatches = [...userNotInterestedMatches, startupToAdd];
        }
        if (!startupNotInterestedMatches.includes(userId)) {
          updatedStartupNot = [...startupNotInterestedMatches, investorToAdd];
        }
        await updateDoc(userRef, { notInterestedMatches: updatedMatches });
        await updateDoc(startupRef, {
          notInterestedMatches: updatedStartupNot,
          investorInterests: updatedInvestorInterests,
          investorContacts: updatedInvestorContacts,
          archivedMatches: updatedArchivedStartup
        });
        //console.log(`Match ${matchId} added to not interested`);
      }
    } catch (error) {
      // console.error("Error not interested match:", error);
    }
  };

  const storeInterestedInStartup = async (docId, matchId) => {
    try {
      const userDocRef = doc(db, "users", docId);
      const userDoc = await getDoc(userDocRef);

      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists()) {
        let updatedInvestorInterests;
        const startupInvestorInterests =
          startupDoc.data().investorInterests || [];
        const investorInterestToAdd = userDoc.id;
        updatedInvestorInterests = [
          ...startupInvestorInterests,
          investorInterestToAdd,
        ];

        await updateDoc(startupDocRef, {
          investorInterests: updatedInvestorInterests,
        });
        // console.log(`Match ${matchId} received investor`);
      } else {
        // console.log("No such document with matchId:", matchId);
      }
    } catch (error) {
      console.error("Error storing investor interest", error);
    }
  };

  //
  const handleInterestedEmailNotification = async (docId, matchId) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists() && startupDoc.data().emailConsent) {
        // Send an email to the startup about a new investor match
        const payload = {
          sender: {
            name: "PRAIRI",
            email: "info@prairi.com",
          },
          to: [
            {
              name: startupDoc.data().contactName,
              email: startupDoc.data().email,
            },
          ],
          subject:
            "Congratulations! PRAIRI has found a new Investor match for you.",
          templateId: 33,
          params: {
            DOCID: startupDoc.id,
            EMAIL: startupDoc.data().email,
            INVID: docId,
            NAME: startupDoc.data().contactName,
          },
        };

        const { data } = await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });

        // console.log(data);
        // setEmailSent(true);
      }
    } catch (err) {
      console.error("Error sending new match email to startup", err);
    }
  };

  const handleInterestedSMSNotification = async (matchId) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists() && startupDoc.data().smsConsent) {
        const messagesCollection = collection(db, "messages");
        const dataToStore = {
          to: `+${startupDoc.data().phone}`,
          body: "Congratulations! PRAIRI has found a new Investor who is interested in your startup! Check them out on your dashboard: https://prairi.com/dashboard",
        };
        await addDoc(messagesCollection, dataToStore);
      }
    } catch (error) {
      console.error("Error sending SMS notification to startup", error);
    }
  };

  const storeFeedback = async (matchId, userId) => {
    try {
      let feedbackToAdd = notInterestedReasons;
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      let feedbackCount = userDoc.data().feedbacksGiven || 0;
      if (document.getElementById("other").checked && textReason) {
        feedbackToAdd.push(textReason);
      }

      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists()) {
        const currentDate = new Date().toISOString();
        const data = {
          [currentDate]: feedbackToAdd,
        };

        let updatedFeedbacks;
        const startupInvestorFeedbacks =
          startupDoc.data().investorFeedback || [];
        updatedFeedbacks = [...startupInvestorFeedbacks, data];

        await updateDoc(startupDocRef, { investorFeedback: updatedFeedbacks });
        await updateDoc(userRef, { feedbacksGiven: feedbackCount + 1 });
        // console.log("Feedback stored for startup");
      } else {
        // console.log("No such document with matchId:", matchId);
      }
    } catch (error) {
      console.error("Error storing feedback", error);
    }
  };

  const storeFlag = async (matchId, reason) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);
      const userRef = doc(db, "users", currentUserId);
      if (startupDoc.exists()) {
        let updatedFlags;
        const startupFlags = startupDoc.data().flags || [];
        updatedFlags = [...startupFlags, reason];

        await updateDoc(startupDocRef, { flags: updatedFlags });
        updateLatestActions(
          userRef,
          "Flagged a startup from dashboard",
          isMobile
        );
        // console.log("Flag stored for startup");
      } else {
        // console.log("No such document with matchId:", matchId);
      }
    } catch (error) {
      console.error("Error storing flag", error);
    }
  };

  const handleFeedbackEmailNotification = async (matchId) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists() && startupDoc.data().emailConsent) {
        // Send an email to the startup about feedback
        const payload = {
          sender: {
            name: "PRAIRI",
            email: "info@prairi.com",
          },
          to: [
            {
              name: startupDoc.data().contactName,
              email: startupDoc.data().email,
            },
          ],
          subject: "We matched you with an investor...",
          templateId: 34,
          params: {
            EMAIL: startupDoc.data().email,
          },
        };

        const { data } = await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });

        // console.log(data);
        const lastNotification = new Date().toISOString();
        await updateDoc(startupDocRef, { lastNotification: lastNotification });
        //setEmailSent(true);
      }
    } catch (error) {
      console.error("Error sending feedback email to startup", error);
    }
  };

  const handleFeedbackSMSNotification = async (matchId) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists() && startupDoc.data().smsConsent) {
        const messagesCollection = collection(db, "messages");
        const dataToStore = {
          to: `+${startupDoc.data().phone}`,
          body: "PRAIRI matched you with an investor, but they were not interested. However, they left some feedback for you. Check it out on your dashboard: https://prairi.com/dashboard",
        };

        await addDoc(messagesCollection, dataToStore);
      }
    } catch (error) {
      console.error("Error sending feedback SMS to startup", error);
    }
  };

  const handleInterestedClick = () => {
    setInterestedStyle({ borderColor: "#92d050", color: "white" });
    setArchivedStyle({ borderColor: "#a5a5a5", color: "#a5a5a5" });
    setShowInterested(true);
    setShowArchived(false);
  };

  const handleArchivedClick = () => {
    setInterestedStyle({ borderColor: "#a5a5a5", color: "#a5a5a5" });
    setArchivedStyle({ borderColor: "#ffc000", color: "white" });
    setShowArchived(true);
    setShowInterested(false);
  };

  const handleInterest = async (matchId) => {
    try {
      //console.log("starting interest function")
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      storeInterestedInStartup(currentUserId, matchId);
      handleInterestedEmailNotification(currentUserId, matchId);
      handleInterestedSMSNotification(currentUserId, matchId);
      removeArchivedMatch(currentUserId, matchId);
      addInterestedMatch(currentUserId, matchId);
      handleInterestedEmailNotification(currentUserId, matchId);
      const updatedArchivedData = archivedStartupsData.filter(
        (item) => item.id !== matchId
      );
      setArchivedStartupsData(updatedArchivedData);
      updateLatestActions(
        userRef,
        `Selected Interested in ${startupDoc.data().formData.company.company} (dashboard)`,
        isMobile
      );
      addToSummary(matchId, `Selected 'Interested'`, currentUserId)
      setInterestedPopUp(false);
      fetchData();
      fetchInterestedById();
      //console.log("handle interested")
      // console.log("doc id", docId)
    } catch (error) {
      // Handle the error
      // console.log("Error updating document:", error);
    }
  };

  const handleNotInterestFromInterestedFeedback = async (
    docId,
    matchId,
    feedback
  ) => {
    try {
      // handleInterestedEmailNotification(docId, matchId)
      if (notInterestedReasons.length === 0) {
        handleNotInterestFromInterestedNoFeedback(docId, matchId);
        return;
      }
      storeFeedback(matchId, docId);
      const currentDate = new Date().toISOString();
      const usersCollection = collection(db, "users");

      // Fetch the user document by matching on the "id" field
      const startupDocRef = doc(usersCollection, matchId);
      const startupDocSnap = await getDoc(startupDocRef);

      if (startupDocSnap.exists()) {
        // Access the user data using startupDocSnap.data()
        const startupData = startupDocSnap.data();

        const lastNotificationDate =
          (startupData.lastNotification || "").split("T")[0] ?? "";

        if (lastNotificationDate !== currentDate.split("T")[0]) {
          handleFeedbackEmailNotification(matchId);
          handleFeedbackSMSNotification(matchId);
        }

        removeInterestedMatch(docId, matchId);
        addNotInterestedMatch(docId, matchId);

        const updatedInterestedData = interestedStartupsData.filter(
          (item) => item.id !== matchId
        );
        setInterestedStartupsData(updatedInterestedData);
        updateLatestActions(
          userRef,
          `Selected Not Interested in ${startupData.formData.company.company} and provided feedback (dashboard)`,
          isMobile
        );
        addToSummary(matchId, `Selected 'Not Interested' and provided feedback`, currentUserId)
        setNotInterestedPopUp(false);
      } else {
        console.log("User document not found");
      }
    } catch (error) {
      // Handle the error
      console.error("Error handling not interested from feedback:", error);
    }
  };

  const handleFlaggingFromInterestedFeedback = async (
    docId,
    matchId,
    reason
  ) => {
    try {
      storeFlag(matchId, reason);
      const usersCollection = collection(db, "users");

      // Fetch the user document by matching on the "id" field
      const startupDocRef = doc(usersCollection, matchId);
      const startupDocSnap = await getDoc(startupDocRef);

      if (startupDocSnap.exists()) {
        // Access the user data using startupDocSnap.data()
        const startupData = startupDocSnap.data();

        removeInterestedMatch(docId, matchId);
        addNotInterestedMatch(docId, matchId);

        const updatedInterestedData = interestedStartupsData.filter(
          (item) => item.id !== matchId
        );
        setInterestedStartupsData(updatedInterestedData);
        setFlagFromInterestedPopUp(false);
        setThankYouPopUp(true);

        const payload = {
          sender: {
            name: "PRAIRI",
            email: "invite@prairi.com",
          },
          to: [
            {
              name: "Carolina",
              email: "kro.ruz@gmail.com",
            },
            {
              name: "Peter",
              email: "peter@prairi.com",
            },
            //   {name:"hoorieh",
            // email: "hoorieh.23@gmail.com"},
          ],
          subject: "Flagged user",
          textContent: "This user has been flagged",
          templateId: 35,
          params: {
            INVESTOR: docId,
            STARTUP: matchId,
            REASON: reason,
          },
        };

        const { data } = await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });
      } else {
        console.log("User document not found");
      }
    } catch (error) {
      // Handle the error
      console.error("Error handling flagging from interested feedback:", error);
    }
  };

  const handleNotInterestFromArchivedFeedback = async (
    docId,
    matchId,
    feedback
  ) => {
    try {
      // handleInterestedEmailNotification(docId, matchId)
      if (notInterestedReasons.length === 0) {
        handleNotInterestFromArchivedNoFeedback(docId, matchId);
        return;
      }
      storeFeedback(matchId, docId);
      const currentDate = new Date().toISOString();
      const usersCollection = collection(db, "users");

      // Fetch the user document by matching on the "id" field
      const startupDocRef = doc(usersCollection, matchId);
      const startupDocSnap = await getDoc(startupDocRef);

      if (startupDocSnap.exists()) {
        // Access the user data using startupDocSnap.data()
        const startupData = startupDocSnap.data();

        const lastNotificationDate =
          (startupData.lastNotification || "").split("T")[0] ?? "";

        if (lastNotificationDate !== currentDate.split("T")[0]) {
          handleFeedbackEmailNotification(matchId);
          handleFeedbackSMSNotification(matchId);
        }

        removeArchivedMatch(docId, matchId);
        addNotInterestedMatch(docId, matchId);

        const updatedArchivedData = archivedStartupsData.filter(
          (item) => item.id !== matchId
        );
        setArchivedStartupsData(updatedArchivedData);
        updateLatestActions(
          userRef,
          `Selected Not Interested in ${startupData.formData.company.company} and provided feedback (dashboard)`,
          isMobile
        );
        addToSummary(matchId, `Selected 'Not Interested' and provided feedback`, currentUserId)
        setNotInterestedPopUpFromArchived(false);
      } else {
        console.log("User document not found");
      }
    } catch (error) {
      // Handle the error
      console.error(
        "Error handling not interested from archived feedback:",
        error
      );
    }
  };

  const handleFlaggingFromArchivedFeedback = async (docId, matchId, reason) => {
    try {
      storeFlag(matchId, reason);
      const usersCollection = collection(db, "users");

      // Fetch the user document by matching on the "id" field
      const startupDocRef = doc(usersCollection, matchId);
      const startupDocSnap = await getDoc(startupDocRef);

      if (startupDocSnap.exists()) {
        // Access the user data using startupDocSnap.data()
        const startupData = startupDocSnap.data();

        removeArchivedMatch(docId, matchId);
        addNotInterestedMatch(docId, matchId);

        const updatedArchivedData = archivedStartupsData.filter(
          (item) => item.id !== matchId
        );
        setArchivedStartupsData(updatedArchivedData);

        setFlagFromArchivedPopUp(false);
        setNotInterestedPopUpFromArchived(false);
        setThankYouPopUp(true);

        const payload = {
          sender: {
            name: "PRAIRI",
            email: "invite@prairi.com",
          },
          to: [
            {
              name: "Carolina",
              email: "kro.ruz@gmail.com",
            },
            {
              name: "Peter",
              email: "peter@prairi.com",
            },
            //   {name:"hoorieh",
            // email: "hoorieh.23@gmail.com"},
          ],
          subject: "Flagged user",
          textContent: "This user has been flagged",
          templateId: 35,
          params: {
            INVESTOR: docId,
            STARTUP: matchId,
            REASON: reason,
          },
        };

        const { data } = await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });
      } else {
        console.log("User document not found");
      }
    } catch (error) {
      // Handle the error
      console.error("Error handling flagging from archived feedback:", error);
    }
  };

  const handleNotInterestFromInterestedNoFeedback = async (docId, matchId) => {
    try {
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      removeInterestedMatch(docId, matchId);
      addNotInterestedMatch(docId, matchId);
      const updatedInterestedData = interestedStartupsData.filter(
        (item) => item.id !== matchId
      );
      setInterestedStartupsData(updatedInterestedData);
      updateLatestActions(
        userRef,
        `Selected Not Interested in ${startupDoc.data().formData.company.company} and chose not to provide feedback (dashboard)`,
        isMobile
      );
      addToSummary(matchId, `Selected 'Not Interested' and chose not to provide feedback`, currentUserId)
      setNotInterestedPopUp(false);
    } catch (error) {
      // Handle the error
      // console.log("Error updating document:", error);
    }
  };

  const handleNotInterestFromArchivedNoFeedback = async (docId, matchId) => {
    try {
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      removeArchivedMatch(docId, matchId);
      addNotInterestedMatch(docId, matchId);
      const updatedArchivedData = archivedStartupsData.filter(
        (item) => item.id !== matchId
      );
      setArchivedStartupsData(updatedArchivedData);
      updateLatestActions(
        userRef,
        `Selected Not Interested in ${startupDoc.data().formData.company.company} and chose not to provide feedback (dashboard)`,
        isMobile
      );
      addToSummary(matchId, `Selected 'Not Interested' and chose not to provide feedback`, currentUserId)
      setNotInterestedPopUpFromArchived(false);
    } catch (error) {
      // Handle the error
      // console.log("Error updating document:", error);
    }
  };

  const sendShareEmail = async (docId, potentialId) => {

    try {
      const sharedUsersCollection = collection(db, "sharedUsers");
      const startupDocRef = doc(sharedUsersCollection, docId);
      const startupDocSnap = await getDoc(startupDocRef);
      //console.log('send email' + JSON.stringify(currentUser))
     // console.log(docId);
      //console.log(potentialName);
      if (startupDocSnap.exists()) {
        const payload = {
          sender: {
            name: "PRAIRI",
            email: "invite@prairi.com",
          },
          to: [
            {
              name: potentialName,
              email: potentialEmail,
            },
          ],
          templateId: 46,
          params: {
            STARTUPNAME: startupDocSnap.data().formData.company.company,
            INVESTORNAME: currentUserData.contactName,
            STARTUPID: docId,
            NAME: potentialName.split(" ")[0],
            POTENTIALID: potentialId,
          },
        };
        await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });
      }
    } catch (error) {
      // Handle error
      console.log(error);
    }
  };

  const sendShareSMS = async (docId, phone, id) => {
    try {
      const messagesCollection = collection(db, "messages");
      let processedPhone;
      phone.charAt(0) === "+"
        ? (processedPhone = phone)
        : (processedPhone = `+${phone}`);
      const dataToStore = {
        to: processedPhone,
        body: `Hi, ${potentialName.split(' ')[0]}. ${currentUserData.contactName} has sent you a startup's profile on PRAIRI that you might be interested in. https://prairi.com/startup/${docId}/${id}`,
      };
      await addDoc(messagesCollection, dataToStore);
    } catch (error) {
      console.log(error);
    }
  };

  const handleShare = async (docId) => {
    const startupRef = doc(db, "users", docId);
    const startupDoc = await getDoc(startupRef);
    if (potentialPhone==="" && potentialEmail===""){
      console.log("missing data")
      setShareError("Please provide an email address or phone number")
      return;
    }
    let processedPhone;
    processedPhone =
      potentialPhone.charAt(0) === "+"
        ? (processedPhone = potentialPhone.replace("+", ""))
        : potentialPhone;

        const formData = startupDoc.data().formData;

        const sharedUserRef = await addDoc(collection(db, "sharedUsers"), {
          formData: formData,
          userId: docId,
          coleagueEmail: currentUserData.email,
          coleaguePhone: currentUserData.phone,
          coleagueContactName: currentUserData.contactName,
          coleagueSmsConsent: currentUserData.smsConsent,
          coleagueEmailConsent: currentUserData.emailConsent,
        });
        const sharedUserId = sharedUserRef.id;

    const docRef = await addDoc(collection(db, "potential"), {
      username: potentialName,
      email: potentialEmail,
      phone: processedPhone,
      category: "investor",
      sharedBy: currentUserId,
      name: potentialName.split(" ")[0],
    });
    const id = docRef.id
    console.log(id)
    setPotentialId(id);
    sendShareEmail(sharedUserId, id);
    updateLatestActions(userRef, `Shared startup ${startupDoc.data().formData.company.company}with another investor`, isMobile)
    addToSummary(docId, `Shared your profile with another investor outside of PRAIRI`, currentUserId)
    sendShareSMS(sharedUserId, potentialPhone,  id);
    setTySharePopup(true);
    //console.log("sent?")
  };

  const storeOkPopup = async (docId) => {
    const userRef = doc(db, "users", docId);
    updateLatestActions(
      userRef,
      "Clicked on 'welcome back' popup in db",
      isMobile
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    updateLatestActions(userRef, "Dashboard view", isMobile);
  }, []);

  const redirectToLogin = () => {
    navigate("/login"); // Redirect to the login page
  };

  const redirectToForms = () => {
    //search for current position of the user in the forms
    storeOkPopup(currentUserId);
    navigate("/startupregistration");
  };

  const handleName = (e) => {
    setPotentialName(e.target.value);
  };

  const handleEmail = (e) => {
    setPotentialEmail(e.target.value);
  };

  const handlePhone = (e) => {
    setPotentialPhone(e.target.value);
  };

  useEffect(() => {
    if (!currentUser && dataFetched) {
      redirectToLogin();
    }
  }, [currentUser]);

  useEffect(() => {
    // Adjust the container's height based on the content height
  }, [showInterested, showArchived]);

  const { uid } = currentUser || {};

  const isAdminUser =
    currentUser && currentUser.uid === "A3Go76mJaLT2GeqHsxlYZRCOWur2";

  const isTestInvestor =
    currentUser && currentUser.uid === "63ZaAMsEq6UqenQ8CmFVxho5sZe2";


  if (isTestInvestor) {
    navigate("/investorregistration");
    return null;
  }

  if (!dataFetched) {
    return (
      <div
        className="md:max-w-lg p-2 from-navStart to-navEnd px-4 flex flex-col "
        ref={containerRef}
        style={{ marginTop: navBarHeight + 30 }}
      >
        <Loading />
      </div>
    ); // redirect to login, TO DO.
  } else {
    return (
      <div
        className="md:max-w-lg p-1 from-navStart pt-5 pb-2 to-navEnd px-4 flex flex-col "
        ref={containerRef}
        style={{ marginTop: navBarHeight + 4 }}
      >
        {/* <h1>{JSON.stringify(interestedStartupsData)}</h1> */}

        <div>
          {showInterested && (
            <div className="flex space-x-8 justify-center items-start p-2 mb-5">
              <Badge
                content={interestedStartupsData.length}
                color="#303030"
                border="#92d050"
              >
                <button
                  className="
                    font-[sans-serif]  tracking-wide font bg-#2E2E2E  border-[2px]  rounded-md py-1  
                    w-[30vw] max-w-[150px] min-w-[94px]
                    sm:w-[150px] "
                  style={interestedStyle}
                  onClick={handleInterestedClick}
                >
                  Interested
                </button>
              </Badge>

              <Badge
                content={archivedStartupsData.length}
                color="#303030"
                border="#c8c8c8"
              >
                <button
                  className="            
                font-[sans-serif]  tracking-wide font bg-#2E2E2E border-[2px]  rounded-md py-1                 
                w-[30vw] max-w-[150px] min-w-[94px]
                sm:w-[150px] "
                  style={archivedStyle}
                  onClick={handleArchivedClick}
                >
                  Archived
                </button>
              </Badge>
            </div>
          )}
          {showArchived && (
            <div className="flex space-x-8 justify-center items-start p-2 mb-5">
              <Badge
                content={interestedStartupsData.length}
                border="#c8c8c8"
                color="#303030"
              >
                <button
                  className="
                    font-[sans-serif]  tracking-wide font bg-#2E2E2E  border-[2px]  rounded-md py-1  
                    w-[30vw] max-w-[150px] min-w-[94px]
                    sm:w-[150px] "
                  style={interestedStyle}
                  onClick={handleInterestedClick}
                >
                  Interested
                </button>
              </Badge>
              <Badge
                content={archivedStartupsData.length}
                color="#303030"
                border="#ffc000"
              >
                <button
                  className="            
                font-[sans-serif]  tracking-wide font bg-#2E2E2E border-[2px]  rounded-md py-1                 
                w-[30vw] max-w-[150px] min-w-[94px]
                sm:w-[150px] "
                  style={archivedStyle}
                  onClick={handleArchivedClick}
                >
                  Archived
                </button>
              </Badge>
            </div>
          )}

          {showInterested && interestedStartupsData.length > 0 && (
            <div>
              <h1 className="text-#f2f2f2 font-bold text-center pb-5">
                My Matches
              </h1>
              {/* <div>{JSON.stringify(interestedStartupsData)}</div>
              <StartupButton startup={interestedStartupsData[0]}/> */}
              <div>
                {/* {console.log('interestedStartupsData pre loop', interestedStartupsData)} */}
                {interestedStartupsData.map((startup) => (
                  <div
                    className="mb-2 items-center flex justify-center"
                    key={startup.id}
                  >
                    <div
                      style={{
                        width: isMobile? "345px": "450px", // Default width for the button
                        // Add more media queries and styles as needed
                      }}
                      className="items-center flex mb-2 w-1/3 justify-center"
                    >
                      <StartupButton
                        startup={startup}
                        status="interested"
                        investor={currentUserData}
                        share={() => handleShare(startup.id)}
                        state={currentUserData.formData.company.startupstates.length<usStates.length}
                        notInterested={() => setNotInterestedPopUp(startup.id)}
                        setEmail={handleEmail}
                        setName={handleName}
                        setPhone={handlePhone}
                        shareError={shareError}
                        isMobile={isMobile}
                        pitchTracking={()=>pitchTracking(startup.id, currentUserId, isMobile)}
                        urlTracking={()=>urlTracking(startup.id, currentUserId, isMobile)}
                      />
                    </div>
                    <div>
                      <Modal
                        border="red"
                        open={notInterestedPopUp === startup.id}
                      >
                        <div>
                          <div className="flex justify-center items-center mb-5 mt-2">
                            <FlagLink
                              click={() =>
                                setFlagFromInterestedPopUp(startup.id)
                              }
                            />
                          </div>
                          <form>
                            <label for="checkboxes-group">
                              To help this startup so that they may one day be
                              ready to receive investment from you, please
                              provide your feedback.
                              <span className="font-bold">
                                You will remain anonymous.
                              </span>
                            </label>
                            <br />
                            <div className="flex mt-4 items-center">
                              <input
                                className="mr-5  w-5 h-5"
                                type="checkbox"
                                id="no-traction"
                                name="checkboxes-group"
                                value="Not enough traction"
                                onChange={handleNotInterestedReason}
                              />
                              <label for="no-traction">
                                {" "}
                                Not enough traction
                              </label>
                            </div>
                            <br />
                            <div className="flex  items-center">
                              <input
                                className="mr-5  w-5 h-5"
                                type="checkbox"
                                id="team-not-strong"
                                name="checkboxes-group"
                                value="Team not strong enough"
                                onChange={handleNotInterestedReason}
                              />
                              <label for="team-not-strong">
                                {" "}
                                Team not strong enough
                              </label>
                            </div>
                            <br />
                            <div className="flex  items-center">
                              <input
                                className="mr-5  w-5 h-5"
                                type="checkbox"
                                id="bad-timing"
                                name="checkboxes-group"
                                value="Bad timing for this idea or market"
                                onChange={handleNotInterestedReason}
                              />
                              <label for="bad-timing">
                                {" "}
                                Bad timing for this idea or market
                              </label>
                            </div>
                            <br />
                            <div className="flex  items-center">
                              <input
                                className="mr-5  w-5 h-5"
                                type="checkbox"
                                id="competitive-landscape"
                                name="checkboxes-group"
                                value="Landscape is too competitive"
                                onChange={handleNotInterestedReason}
                              />
                              <label for="competitive-landscape">
                                {" "}
                                Landscape is too competitive
                              </label>
                            </div>
                            <br />
                            <div className="flex  items-center">
                              <input
                                className="mr-5  w-5 h-5"
                                type="checkbox"
                                id="higher-capital"
                                name="checkboxes-group"
                                value="Much higher capital would be needed to succeed"
                                onChange={handleNotInterestedReason}
                              />
                              <label for="higher-capital">
                                {" "}
                                Much higher capital would be needed to succeed
                              </label>
                            </div>
                            <br />
                            <div className="flex  items-center">
                              <input
                                className="mr-5  w-5 h-5"
                                type="checkbox"
                                id="other"
                                name="checkboxes-group"
                                value="other"
                                onChange={handleNotInterestedReason}
                              />
                              <label for="other">
                                {" "}
                                Other reason:{" "}
                                <input
                                  type="text"
                                  className="w-32 ml-4 rounded-lg text-black"
                                  id="other-reason"
                                  name="other-reason"
                                  onChange={handleNotInterestedReasonText}
                                />
                              </label>
                            </div>
                          </form>
                          <div className="mt-5 mb-5">
                            <TextButton
                              label="Don't wish to leave feedback"
                              click={() =>
                                handleNotInterestFromInterestedNoFeedback(
                                  currentUserId,
                                  startup.id
                                )
                              }
                            />
                          </div>
                          <div className="flex justify-between items-center  w-[80%]">
                            <PrairiButton
                              bgColor="regular"
                              border="red"
                              size="md"
                              rounded="full"
                              label="Send"
                              click={() =>
                                handleNotInterestFromInterestedFeedback(
                                  currentUserId,
                                  startup.id,
                                  notInterestedReasons
                                )
                              }
                            />

                            <div className="ml-13">
                              <TextButton
                                label="undo"
                                click={() => setNotInterestedPopUp(false)}
                              />
                            </div>
                          </div>
                        </div>
                      </Modal>
                      <Modal
                        border="red"
                        open={flagFromInterestedPopUp === startup.id}
                      >
                        <div className=" m-3">
                          <form>
                            <img
                              src={Flag}
                              className="h-12 mx-auto block mb-5"
                              alt="flag"
                            />
                            <label for="checkboxes-group " className="text-sm">
                              <div className="font-bold ">
                                Report this profile and we'll look into it.
                              </div>
                              PRAIRI personally checks each user before allowing
                              them to join, but it's possible this one slipped
                              past us.
                            </label>
                            <div className="flex flex-col mt-5">
                              <div className="flex mb-4">
                                <input
                                  type="radio"
                                  id="fake-profile"
                                  name="flagReason"
                                  value="Fake profile"
                                  onChange={handleFlagReason}
                                  className="mr-3 w-5"
                                />
                                <label htmlFor="fake-profile">
                                  Fake profile
                                </label>
                              </div>
                              <div className="flex mb-4">
                                <input
                                  type="radio"
                                  id="scammer"
                                  name="flagReason"
                                  value="Scammer"
                                  onChange={handleFlagReason}
                                  className="mr-3 w-5"
                                />
                                <label htmlFor="fake-profile">Scammer</label>
                              </div>
                              <div className="flex mb-4">
                                <input
                                  type="radio"
                                  id="abusive"
                                  name="flagReason"
                                  value="Abusive to you or others"
                                  onChange={handleFlagReason}
                                  className="mr-3 w-5"
                                />
                                <label htmlFor="fake-profile">
                                  Abusive to you or others
                                </label>
                              </div>
                              <div className="flex mb-4">
                                <input
                                  type="radio"
                                  id="other"
                                  name="flagReason"
                                  value="Other"
                                  onChange={handleFlagReason}
                                  className="mr-3 w-5"
                                />
                                <label htmlFor="other">Other</label>
                                <input
                                  type="text"
                                  className="w-52 rounded-lg text-black ml-3"
                                  id="otherReason"
                                  name="otherReason"
                                  onChange={(event) =>
                                    setFlagReason(event.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="flex w-[80%] justify-between items-center">
                          <PrairiButton
                            bgColor="regular"
                            border="red"
                            size="md"
                            rounded="full"
                            label="Send"
                            click={() =>
                              handleFlaggingFromInterestedFeedback(
                                currentUserId,
                                startup.id,
                                flagReason
                              )
                            }
                          />

                          <div className="ml-3">
                            <TextButton
                              label="back"
                              click={() => setFlagFromInterestedPopUp(false)}
                            />
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {showInterested && interestedStartups.length === 0 && (
            <div>
              <h1 className="text-#f2f2f2 font-bold text-center pb-5">
                No matches yet
              </h1>
              <p className="text-[#a5a5a5] ">
                How often you match is based on the criteria you set in your
                Data Profile and the startups/investors on our platform that
                match your criteria (including their location, industry, stage,
                the amount of investment, etc.).
              </p>
            </div>
          )}
          {showArchived && archivedStartupsData.length > 0 && (
            <div>
              <h1 className="text-#f2f2f2 font-bold text-center pb-5">
                My Archived Matches
              </h1>
              <div>
                {/* {console.log('archivedStartupsData pre loop', archivedStartupsData)} */}
                {archivedStartupsData.map((startup) => (
                  <div
                    className="mb-2 items-center flex justify-center"
                    key={startup.id}
                  >
                    <div
                      style={{
                        width: isMobile? "345px": "450px" // Default width for the button
                        // Add more media queries and styles as needed
                      }}
                      className="items-center flex justify-center"
                    >
                      <StartupButton
                        startup={startup}
                        status="archived"
                        interested={() => setInterestedPopUp(startup.id)}
                        notInterested={() =>
                          setNotInterestedPopUpFromArchived(startup.id)
                        }
                        investor={currentUserData}
                        share={() => handleShare(startup.id)}
                        setEmail={handleEmail}
                        setName={handleName}
                        setPhone={handlePhone}
                        shareError={shareError}
                        isMobile={isMobile}
                        state={currentUserData.formData.company.startupstates.length<usStates.length}
                        pitchTracking={()=>pitchTracking(startup.id, currentUserId, isMobile)}
                        urlTracking={()=>urlTracking(startup.id, currentUserId, isMobile)}
                      />
                    </div>
                    <div className="">
                      <Modal
                        border="green"
                        open={interestedPopUp === startup.id}
                      >
                        <div className=" m-3">
                          <p>
                            {" "}
                            Great! We’ll let them know and provide you with
                            their contact info.
                          </p>
                        </div>
                        <div className="flex justify-between items-center  w-[80%]">
                          <PrairiButton
                            bgColor="regular"
                            border="green"
                            size="md"
                            rounded="full"
                            label="OK"
                            click={() => handleInterest(startup.id)}
                          />
                          <TextButton
                            label="undo"
                            click={() => setInterestedPopUp(false)}
                          />
                        </div>
                      </Modal>
                    </div>
                    <div>
                      <Modal
                        border="red"
                        open={notInterestedPopUpFromArchived === startup.id}
                      >
                        <div>
                          <div className="flex justify-center items-center mb-5 mt-2">
                            <FlagLink
                              click={() => setFlagFromArchivedPopUp(startup.id)}
                            />
                          </div>
                          <form>
                            <label for="checkboxes-group">
                              To help this startup so that they may one day be
                              ready to receive investment from you, please
                              provide your feedback.
                              <span className="font-bold">
                                You will remain anonymous.
                              </span>
                            </label>
                            <br />
                            <div className="flex mt-4 items-center">
                              <input
                                className="mr-5  w-5 h-5"
                                type="checkbox"
                                id="no-traction"
                                name="checkboxes-group"
                                value="Not enough traction"
                                onChange={handleNotInterestedReason}
                              />
                              <label for="no-traction">
                                {" "}
                                Not enough traction
                              </label>
                            </div>
                            <br />
                            <div className="flex  items-center">
                              <input
                                className="mr-5  w-5 h-5"
                                type="checkbox"
                                id="team-not-strong"
                                name="checkboxes-group"
                                value="Team not strong enough"
                                onChange={handleNotInterestedReason}
                              />
                              <label for="team-not-strong">
                                {" "}
                                Team not strong enough
                              </label>
                            </div>
                            <br />
                            <div className="flex  items-center">
                              <input
                                className="mr-5  w-5 h-5"
                                type="checkbox"
                                id="bad-timing"
                                name="checkboxes-group"
                                value="Bad timing for this idea or market"
                                onChange={handleNotInterestedReason}
                              />
                              <label for="bad-timing">
                                {" "}
                                Bad timing for this idea or market
                              </label>
                            </div>
                            <br />
                            <div className="flex  items-center">
                              <input
                                className="mr-5  w-5 h-5"
                                type="checkbox"
                                id="competitive-landscape"
                                name="checkboxes-group"
                                value="Landscape is too competitive"
                                onChange={handleNotInterestedReason}
                              />
                              <label for="competitive-landscape">
                                {" "}
                                Landscape is too competitive
                              </label>
                            </div>
                            <br />
                            <div className="flex  items-center">
                              <input
                                className="mr-5  w-5 h-5"
                                type="checkbox"
                                id="higher-capital"
                                name="checkboxes-group"
                                value="Much higher capital would be needed to succeed"
                                onChange={handleNotInterestedReason}
                              />
                              <label for="higher-capital">
                                {" "}
                                Much higher capital would be needed to succeed
                              </label>
                            </div>
                            <br />
                            <div className="flex  items-center">
                              <input
                                className="mr-5  w-5 h-5"
                                type="checkbox"
                                id="other"
                                name="checkboxes-group"
                                value="other"
                                onChange={handleNotInterestedReason}
                              />
                              <label for="other">
                                {" "}
                                Other reason:{" "}
                                <input
                                  type="text"
                                  className="w-32 ml-3 rounded-lg text-black"
                                  id="other-reason"
                                  name="other-reason"
                                  onChange={handleNotInterestedReasonText}
                                />
                              </label>
                            </div>
                          </form>
                          <div className="mt-5 mb-5">
                            <TextButton
                              label="Don't wish to leave feedback"
                              click={() =>
                                handleNotInterestFromArchivedNoFeedback(
                                  currentUserId,
                                  startup.id
                                )
                              }
                            />
                          </div>
                          <div className="flex justify-between items-center  w-[80%]">
                            <PrairiButton
                              bgColor="regular"
                              border="red"
                              size="md"
                              rounded="full"
                              label="Send"
                              click={() =>
                                handleNotInterestFromArchivedFeedback(
                                  currentUserId,
                                  startup.id,
                                  notInterestedReasons
                                )
                              }
                            />

                            <div className="ml-13">
                              <TextButton
                                label="undo"
                                click={() =>
                                  setNotInterestedPopUpFromArchived(false)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </Modal>
                      <Modal
                        border="red"
                        open={flagFromArchivedPopUp === startup.id}
                      >
                        <div className=" m-3">
                          <form>
                            <img
                              src={Flag}
                              className="h-12 mx-auto block mb-5"
                              alt="flag"
                            />
                            <label for="checkboxes-group " className="text-sm">
                              <div className="font-bold ">
                                Report this profile and we'll look into it.
                              </div>
                              PRAIRI personally checks each user before allowing
                              them to join, but it's possible this one slipped
                              past us.
                            </label>
                            <div className="flex flex-col mt-5">
                              <div className="flex mb-4">
                                <input
                                  type="radio"
                                  id="fake-profile"
                                  name="flagReason"
                                  value="Fake profile"
                                  onChange={handleFlagReason}
                                  className="mr-3 w-5"
                                />
                                <label htmlFor="fake-profile">
                                  Fake profile
                                </label>
                              </div>
                              <div className="flex mb-4">
                                <input
                                  type="radio"
                                  id="scammer"
                                  name="flagReason"
                                  value="Scammer"
                                  onChange={handleFlagReason}
                                  className="mr-3 w-5"
                                />
                                <label htmlFor="fake-profile">Scammer</label>
                              </div>
                              <div className="flex mb-4">
                                <input
                                  type="radio"
                                  id="abusive"
                                  name="flagReason"
                                  value="Abusive to you or others"
                                  onChange={handleFlagReason}
                                  className="mr-3 w-5"
                                />
                                <label htmlFor="fake-profile">
                                  Abusive to you or others
                                </label>
                              </div>
                              <div className="flex mb-4">
                                <input
                                  type="radio"
                                  id="other"
                                  name="flagReason"
                                  value="Other"
                                  onChange={handleFlagReason}
                                  className="mr-3 w-5"
                                />
                                <label htmlFor="other">Other</label>
                                <input
                                  type="text"
                                  className="w-52 rounded-lg text-black ml-3"
                                  id="otherReason"
                                  name="otherReason"
                                  onChange={(event) =>
                                    setFlagReason(event.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="flex w-[80%] justify-between items-center">
                          <PrairiButton
                            bgColor="regular"
                            border="red"
                            size="md"
                            rounded="full"
                            label="Send"
                            click={() =>
                              handleFlaggingFromArchivedFeedback(
                                currentUserId,
                                startup.id,
                                flagReason
                              )
                            }
                          />

                          <div className="ml-3">
                            <TextButton
                              label="back"
                              click={() => setFlagFromArchivedPopUp(false)}
                            />
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {showArchived && archivedStartups.length === 0 && (
            <div>
              <h1 className="text-#f2f2f2 font-bold text-center pb-5">
                No archived matches
              </h1>
            </div>
          )}
        </div>

        <Modal border="no-border" open={thankYouPopUp}>
          <div className="py-5">
            <img
              src={ThankYou}
              alt="Thank you"
              className="w-[80%] mx-auto mb-6"
            />
            <div className="flex place-content-center">
              <PrairiButton
                bgColor="regular"
                border="silver"
                size="sm"
                rounded="soft"
                label="OK"
                click={() => setThankYouPopUp(false)}
              />
            </div>
          </div>
        </Modal>

        <Modal border="silver" open={tySharePopup} size="sm-responsive">
          <div className="pt-5 flex flex-col items-center">
            <img src={Logo} className="w-1/4 mb-2 "></img>
            <div>Thanks for sharing!</div>
            <PrairiButton
              click={() => setTySharePopup(false)}
              border="silver"
              label="Sure"
              rounded="soft"
              size="sm"
            />
          </div>
        </Modal>

        {dataFetched && (
          <CompleteYourProfile
            open={
              !currentUserData.formData ||
              !currentUserData.formData.matching.completed
            }
            click={redirectToForms}
          />
        )}
        {/* {dataFetched && currentUserData && <Modal open={welcomePopup} size="sm-responsive">
        <div className="flex justify-center items-center flex-col text-ineditable w-1/2 py-2">
          <div className="lg:w-[40%]  w-[40%] flex items-center justify-center mb-2"><img  src={Unicorn}></img></div>
          <p className="text-center text-lg whitespace-nowrap">Welcome back, {currentUserData.username.split(" ")[0]}</p>
        </div>
      </Modal>} */}
        {pendingStartups.length > 0  && ( //don't forget to add isMobile check when adding pitch deck to desktop version
          <div className=" flex justify-center items-center mt-8 lg:ml-3 ml-2 ">
            <MatchesReminder click={seeNotifications} />
          </div>
        )}
      </div>
    );
  }
};

export default Dashboard1;
