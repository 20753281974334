import { navBarContext, authContext } from "./context/context";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useContext, useState } from "react";
import ThankYou from "../assets/thankyou-unicorn.png";
import {
  updateLatestActions,
  addToSummary,
  pitchTracking,
  urlTracking,
} from "../utils";
import TextBubble from "../assets/unicornnext.png";
import Flag from "../assets/flag.png";
import {
  NewStartup,
  Modal,
  PrairiButton,
  TextButton,
  Loading,
  FlagLink,
  Pdf,
  RoundedTitle,
  PleaseLogin,
} from "@peterfosso/prairi-components";
import { useMediaQuery } from "react-responsive";
import {
  collection,
  db,
  getDoc,
  doc,
  Timestamp,
  updateDoc,
  addDoc,
} from "../firebase/firebaseApp";
import { deleteDoc } from "firebase/firestore";
import ThatsAll from "./ThatsAll";
import axios from "axios";
//hi

const NewMatch = (props) => {
  const { currentUser } = useContext(authContext);
  const { navBarHeight } = useContext(navBarContext);
  let { docId } = useParams();
  let navigate = useNavigate();
  const [dataFetched, setDataFetched] = useState(false);
  const [matchesData, setMatchesData] = useState([]);
  const [matches, setMatches] = useState([]);
  const [userData, setUserData] = useState(null);
  const userRef = doc(db, "users", docId);
  const [isFirst, setIsFirst] = useState(false);
  const [control, setControl] = useState(true);
  const [thankYouPopUp, setThankYouPopUp] = useState(false);
  const [sawButtons, setSawButtons] = useState(false);
  const [profileViewed, setProfileViewed] = useState(false);
  const [potentialName, setPotentialName] = useState("");
  const [potentialEmail, setPotentialEmail] = useState("");
  const [potentialPhone, setPotentialPhone] = useState("");
  const [viewerSrc, setViewerSrc] = useState(null)
  const [shareError, setShareError] = useState("");
  const [tySharePopup, setTySharePopup] = useState(false);

  const time = Timestamp.fromDate(new Date());
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const redirectTo404 = () => {
    navigate("/404");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    updateLatestActions(userRef, "New match view", isMobile);
  }, []);

  const redirectToLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    updateLatestActions(userRef, "New match view", isMobile);
  }, []);

  useEffect(() => {
    if (potentialPhone !== "" || potentialEmail !== "") {
      setShareError(false);
    }
  }, [potentialEmail, potentialPhone]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement || document.body;
      setSawButtons(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (sawButtons && !profileViewed) {
      //addToSummary(matches[0], "Viewed profile", docId);
      setProfileViewed(true);
    }
  }, [sawButtons, profileViewed]);

  const getDataFromDb = async (docId) => {
    try {
      const userRef = doc(db, "users", docId);
      const userDoc = await getDoc(userRef);
      // console.log(`currentUser inside getDataFromDb ${JSON.stringify(currentUser)}`)
      // console.log(`userDoc inside GetDataFromDb ${JSON.stringify(userDoc.data())}`)
      if (currentUser) {
        if (userDoc.exists()) {
          //console.log("enters userDoc.exists")
          if (currentUser.uid === userDoc.data().uid) {
            setUserData(userDoc.data());
            if (
              !userDoc.data().interestedMatches &&
              !userDoc.data().notInterestedMatches &&
              !userDoc.data().archivedMatches
            ) {
              setIsFirst(true);
              // console.log('first')
            }
            if (userDoc.data().pendingMatches) {
              setMatches(userDoc.data().pendingMatches.reverse());

              const startupDataPromises = userDoc
                .data()
                .pendingMatches.reverse()
                .map(async (startupId) => {
                  const startupDocRef = doc(db, "users", startupId);
                  const startupDoc = await getDoc(startupDocRef);

                  if (startupDoc.exists()) {
                    return {
                      ...startupDoc.data(),
                      id: startupDoc.id,
                    };
                  }

                  return null; // Handle the case where the document doesn't exist
                });

              const startupData = await Promise.all(startupDataPromises);

              setMatchesData(startupData.filter(Boolean)); // Filter out null values
              setViewerSrc(matchesData[0].formData.company.deck)
              //console.log(`matchesData ${JSON.stringify(matchesData.length)}`)
            }
          } else {
            //console.log("redirected from currentUser.uid=== userDoc.data().uid")
            redirectTo404();
            return;
          }
        } else {
          //console.log("redirected from no currentUser")
          redirectTo404();
          return;
        }
      }
    } catch (error) {
      console.log(error);
      // console.log("redirected from catch")
      // redirectTo404();
    }
  };

  const [end, setEnd] = useState(false);

  const fetchData = async (docId, matches) => {
    try {
      await getDataFromDb(docId);
      if (dataFetched && matchesData && matchesData.length === 0) {
        navigate("/thatsall");
      }
      // await fetchMatches(matches);

      // updateLatestActions(userRef, `New match view (${matchesData[0].formData.company.company})`, isMobile)

      setDataFetched(true);
    } catch (error) {
      // Handle errors if any of the functions throw an error
      // console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(docId, matches);

    //console.log(JSON.stringify(matchesData))
  }, [matchesData.length]); //REPLACE WITH FUNCTIONS

  const [interestedPopUp, setInterestedPopUp] = useState(false);
  const [archivedPopUp, setArchivedPopUp] = useState(false);
  const [notInterestedPopUp, setNotInterestedPopUp] = useState(false);
  const [flagPopUp, setFlagPopUp] = useState(false);

  const [undoPopUp, setUndoPopUp] = useState(false);
  const [undoReason, setUndoReason] = useState("");
  const [isHidden, setIsHidden] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [notInterestedReasons, setNotInterestedReasons] = useState([]);
  const [flagReason, setFlagReason] = useState(null);
  const [textReason, setTextReason] = useState("");

  const handleInterestedClick = () => {
    // updateLatestActions(userRef, "Clicked on Interested (not confirmed)", isMobile)
    setInterestedPopUp(true);
  };

  const handleArchivedClick = () => {
    //updateLatestActions(userRef, "Clicked on Archived (not confirmed)", isMobile)
    setArchivedPopUp(true);
  };

  const handleNotInterestedClick = () => {
    // updateLatestActions(userRef, "Clicked on Not Interested (not confirmed)", isMobile)
    setNotInterestedPopUp(true);
  };

  const handleFirstOk = (userId) => {
    setControl(false);
    updateLatestActions(userRef, "Clicked OK first match popup", isMobile);
  };

  const handleNotInterestedReason = (event) => {
    const selectedValue = event.target.value;
    if (event.target.checked) {
      if (selectedValue !== "other") {
        setNotInterestedReasons([...notInterestedReasons, selectedValue]);
      }
    } else {
      setNotInterestedReasons(
        notInterestedReasons.filter((value) => value !== selectedValue)
      );
    }
  };

  const handleFlagReason = (event) => {
    const selectedValue = event.target.value;
    setFlagReason(selectedValue);
  };

  const handleNotInterestedReasonText = (event) => {
    const checkbox = document.getElementById("other");
    checkbox.checked = true;
    setTextReason(event.target.value);
  };

  const removePendingMatch = async (userId, matchId) => {
    try {
      // Fetch the current user's document
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      if (userDoc.exists()) {
        const pendingMatches = userDoc.data().pendingMatches;
        const updatedMatches = pendingMatches.filter(
          (match) => match !== matchId
        );
        // Update the user's document with the modified "pendingMatches" array
        await updateDoc(userRef, { pendingMatches: updatedMatches });
        setMatches(updatedMatches);
      } else {
        console.log("User document not found");
      }
      if (startupDoc.exists()) {
        const pendingMatches = startupDoc.data().pendingMatches;
        const updatedMatches = pendingMatches.filter(
          (investor) => investor !== userId
        );
        console.log("startupDoc exists");
        // Update the user's document with the modified "pendingMatches" array
        await updateDoc(startupRef, { pendingMatches: updatedMatches });
        setMatches(updatedMatches);
      } else {
        console.log("User document not found");
      }
    } catch (error) {
      // console.error("Error removing match:", error);
    }
  };

  const addInterestedMatch = async (userId, matchId) => {
    try {
      const userDoc = await getDoc(userRef);
      if (userDoc) {
        let updatedMatches;
        const userInterestedMatches = userDoc.data().interestedMatches || [];
        const startupToAdd = matchId;
        if (!userInterestedMatches.includes(matchId)) {
          updatedMatches = [...userInterestedMatches, startupToAdd];
        }
        await updateDoc(userRef, { interestedMatches: updatedMatches });
        // console.log(`Match ${matchId} added to interested`);
      }
    } catch (error) {
      //console.error("Error removing match:", error);
    }
  }; //

  const addArchivedMatch = async (userId, matchId) => {
    try {
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      //Adding startup so we can track archived for them as well
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      if (userDoc) {
        let updatedMatches;
        let updatedStartupArchived;
        const userArchivedMatches = userDoc.data().archivedMatches || [];
        const startupArchivedMatches = startupDoc.data().archivedMatches || [];
        const investorToAdd = userId;
        const startupToAdd = matchId;
        if (!userArchivedMatches.includes(matchId)) {
          updatedMatches = [...userArchivedMatches, startupToAdd];
        }
        if (!startupArchivedMatches.includes(userId)) {
          updatedStartupArchived = [...startupArchivedMatches, investorToAdd];
        }
        await updateDoc(userRef, { archivedMatches: updatedMatches });

        await updateDoc(startupRef, {
          archivedMatches: updatedStartupArchived,
        });
        // console.log(`Match ${matchId} added to archived`);
      }
    } catch (error) {
      // console.error("Error archivinf match:", error);
    }
  }; //

  const addNotInterestedMatch = async (userId, matchId) => {
    try {
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      if (userDoc) {
        let updatedMatches;
        let updatedStartupNot;
        const userNotInterestedMatches =
          userDoc.data().notInterestedMatches || [];
        const startupNotInterestedMatches =
          startupDoc.data().notInterestedMatches || [];
        const startupToAdd = matchId;
        const investorToAdd = userId;
        if (!userNotInterestedMatches.includes(matchId)) {
          updatedMatches = [...userNotInterestedMatches, startupToAdd];
        }
        if (!startupNotInterestedMatches.includes(userId)) {
          updatedStartupNot = [...startupNotInterestedMatches, investorToAdd];
        }
        await updateDoc(userRef, { notInterestedMatches: updatedMatches });

        await updateDoc(startupRef, {
          notInterestedMatches: updatedStartupNot,
        });
        //console.log(`Match ${matchId} added to not interested`);
      }
    } catch (error) {
      // console.error("Error not interested match:", error);
    }
  };

  const storeFeedback = async (matchId, userId) => {
    try {
      let feedbackToAdd = notInterestedReasons;
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      let feedbackCount = userDoc.data().feedbacksGiven || 0;
      if (document.getElementById("other").checked && textReason) {
        feedbackToAdd.push(textReason);
      }

      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists()) {
        const currentDate = new Date().toISOString();
        const data = {
          [currentDate]: feedbackToAdd,
        };

        let updatedFeedbacks;
        const startupInvestorFeedbacks =
          startupDoc.data().investorFeedback || [];
        updatedFeedbacks = [...startupInvestorFeedbacks, data];

        await updateDoc(startupDocRef, { investorFeedback: updatedFeedbacks });
        await updateDoc(userRef, { feedbacksGiven: feedbackCount + 1 });

        // console.log("Feedback stored for startup");
      } else {
        // console.log("No such document with matchId:", matchId);
      }
    } catch (error) {
      console.error("Error storing feedback", error);
    }
  };

  const storeFlag = async (matchId, reason) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);
      const userRef = doc(db, "users", docId);
      if (startupDoc.exists()) {
        let updatedFlags;
        const startupFlags = startupDoc.data().flags || [];
        updatedFlags = [...startupFlags, reason];

        await updateDoc(startupDocRef, { flags: updatedFlags });

        // console.log("Flag stored for startup");
      } else {
        // console.log("No such document with matchId:", matchId);
      }
    } catch (error) {
      console.error("Error storing flag", error);
    }
  };

  const storeInterestedInStartup = async (docId, matchId) => {
    try {
      const userDocRef = doc(db, "users", docId);
      const userDoc = await getDoc(userDocRef);

      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists()) {
        let updatedInvestorInterests;
        const startupInvestorInterests =
          startupDoc.data().investorInterests || [];
        const investorInterestToAdd = userDoc.id;
        updatedInvestorInterests = [
          ...startupInvestorInterests,
          investorInterestToAdd,
        ];

        await updateDoc(startupDocRef, {
          investorInterests: updatedInvestorInterests,
        });
        // console.log(`Match ${matchId} received investor`);
      } else {
        // console.log("No such document with matchId:", matchId);
      }
    } catch (error) {
      console.error("Error storing investor interest", error);
    }
  };

  const handleInterestedEmailNotification = async (docId, matchId) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);
      if (!startupDoc.data().emailConsent) {
        return;
      }
      if (startupDoc.exists()) {
        // Send an email to the startup about a new investor match
        const payload = {
          sender: {
            name: "PRAIRI",
            email: "info@prairi.com",
          },
          to: [
            {
              name: startupDoc.data().contactName,
              email: startupDoc.data().email,
            },
          ],
          subject:
            "Congratulations! PRAIRI has found a new Investor match for you.",
          templateId: 33,
          params: {
            DOCID: startupDoc.id,
            EMAIL: startupDoc.data().email,
            INVID: docId,
            NAME: startupDoc.data().contactName,
          },
        };

        const { data } = await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });

        // console.log(data);
        setEmailSent(true);
      }
    } catch (err) {
      console.error("Error sending new match email to startup", err);
    }
  };

  const handleInterestedSMSNotification = async (matchId) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists()) {
        if (!startupDoc.data().smsConsent) {
          return;
        }
        const messagesCollection = collection(db, "messages");
        const dataToStore = {
          to: `+${startupDoc.data().phone}`,
          body: "Congratulations! PRAIRI has found a new Investor who is interested in your startup! Check them out on your dashboard: https://prairi.com/dashboard",
        };
        await addDoc(messagesCollection, dataToStore);
      }
    } catch (error) {
      console.error("Error sending SMS notification to startup", error);
    }
  };

  const handleFeedbackEmailNotification = async (matchId) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists()) {
        if (!startupDoc.data().emailConsent) {
          return;
        }
        // Send an email to the startup about feedback
        const payload = {
          sender: {
            name: "PRAIRI",
            email: "info@prairi.com",
          },
          to: [
            {
              name: startupDoc.data().contactName,
              email: startupDoc.data().email,
            },
          ],
          subject: "We matched you with an investor...",
          templateId: 34,
          params: {
            EMAIL: startupDoc.data().email,
          },
        };

        const { data } = await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });

        // console.log(data);
        const lastNotification = new Date().toISOString();
        await updateDoc(startupDocRef, { lastNotification: lastNotification });
        setEmailSent(true);
      }
    } catch (error) {
      console.error("Error sending feedback email to startup", error);
    }
  };

  const handleFeedbackSMSNotification = async (matchId) => {
    try {
      const startupDocRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupDocRef);

      if (startupDoc.exists()) {
        if (!startupDoc.data().smsConsent) {
          return;
        }
        const messagesCollection = collection(db, "messages");
        const dataToStore = {
          to: `+${startupDoc.data().phone}`,
          body: "PRAIRI matched you with an investor, but they were not interested. However, they left some feedback for you. Check it out on your dashboard: https://prairi.com/dashboard",
        };

        await addDoc(messagesCollection, dataToStore);
      }
    } catch (error) {
      console.error("Error sending feedback SMS to startup", error);
    }
  };

  const handleInterested = async (matchId) => {
    try {
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      storeInterestedInStartup(docId, matchId);
      handleInterestedEmailNotification(docId, matchId);
      handleInterestedSMSNotification(matchId);
      removePendingMatch(docId, matchId);
      addInterestedMatch(docId, matchId);
      setMatchesData(matchesData.slice(1));
      updateLatestActions(
        userRef,
        `Selected Interested in ${
          startupDoc.data().formData.company.company
        } (dashboard)`,
        isMobile
      );
      addToSummary(matchId, `Selected 'Interested'`, docId);
      setInterestedPopUp(false);
    } catch (error) {
      // Handle the error
      // console.log("Error updating document:", error);
    }
  };

  const handleArchived = async (matchId) => {
    try {
      const startupRef = doc(db, "users", matchId);
      const startupDoc = await getDoc(startupRef);
      removePendingMatch(docId, matchId);
      addArchivedMatch(docId, matchId);
      setMatchesData(matchesData.slice(1));
      updateLatestActions(
        userRef,
        `Selected Archived in ${
          startupDoc.data().formData.company.company
        } (dashboard)`,
        isMobile
      );
      addToSummary(matchId, `Archived your profile for later viewing`, docId);
      setArchivedPopUp(false);
    } catch (error) {
      // Handle the error
      // console.log("Error updating document:", error);
    }
  };

  const handleNotInterested = async (matchId, feedback, matches) => {
    try {
      storeFeedback(matchId, docId);

      const currentDate = new Date().toISOString();
      const usersCollection = collection(db, "users");

      // Fetch the user document by matching on the "id" field
      const startupDocRef = doc(usersCollection, matchId);
      const startupDocSnap = await getDoc(startupDocRef);

      if (startupDocSnap.exists()) {
        const startupData = startupDocSnap.data();

        const lastNotificationDate =
          (startupData.lastNotification || "").split("T")[0] ?? "";

        if (lastNotificationDate !== currentDate.split("T")[0]) {
          handleFeedbackEmailNotification(matchId);
          handleFeedbackSMSNotification(matchId);
        }

        removePendingMatch(docId, matchId);
        addNotInterestedMatch(docId, matchId);
        setMatchesData(matchesData.slice(1));
        updateLatestActions(
          userRef,
          `Selected Not Interested in ${
            startupDocSnap.data().formData.company.company
          } and provided feedback (new match)`,
          isMobile
        );
        addToSummary(
          matchId,
          `Selected 'Not Interested' and provided feedback`,
          docId
        );
        setNotInterestedPopUp(false);
      } else {
        console.log("User document not found");
      }
    } catch (error) {
      // Handle the error
      console.error("Error handling not interested:", error);
    }
  };

  const handleNotInterestedNoFeedback = async (matchId) => {
    try {
      const usersCollection = collection(db, "users");
      const startupDocRef = doc(usersCollection, matchId);
      const startupDocSnap = await getDoc(startupDocRef);
      removePendingMatch(docId, matchId);
      addNotInterestedMatch(docId, matchId);
      setMatchesData(matchesData.slice(1));
      updateLatestActions(
        userRef,
        `Selected Not Interested in ${
          startupDocSnap.data().formData.company.company
        } and chose not to provide feedback (new match)`,
        isMobile
      );
      addToSummary(
        matchId,
        `Selected 'Not Interested' and chose not to provide feedback`,
        docId
      );
      setNotInterestedPopUp(false);
    } catch (error) {
      // Handle the error
      // console.log("Error updating document:", error);
    }
  };

  const handleFlagging = async (matchId, reason, matches) => {
    try {
      storeFlag(matchId, reason);
      const usersCollection = collection(db, "users");

      // Fetch the user document by matching on the "id" field
      const startupDocRef = doc(usersCollection, matchId);
      const startupDocSnap = await getDoc(startupDocRef);

      if (startupDocSnap.exists()) {
        // Access the user data using startupDocSnap.data()
        const startupData = startupDocSnap.data();

        removePendingMatch(docId, matchId);
        addNotInterestedMatch(docId, matchId);
        setMatchesData(matchesData.slice(1));

        // Your existing logic for sending email via Sendinblue
        const payload = {
          sender: {
            name: "PRAIRI",
            email: "invite@prairi.com",
          },
          to: [
            {
              name: "Carolina",
              email: "kro.ruz@gmail.com",
            },
            {
              name: "Peter",
              email: "peter@prairi.com",
            },
          ],
          subject: "Flagged user",
          textContent: "This user has been flagged",
          templateId: 35,
          params: {
            INVESTOR: docId,
            STARTUP: matchId,
            REASON: reason,
          },
        };

        const { data } = await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });
        updateLatestActions(
          userRef,
          `Flagged ${startupData.formData.company.company}`,
          isMobile
        );
        setFlagPopUp(false);
        setThankYouPopUp(true);
      } else {
        console.log("User document not found");
      }
    } catch (error) {
      // Handle the error
      console.error("Error handling flagging:", error);
    }
  };

  const sendShareEmail = async (docId, potentialId) => {
    try {
      const usersCollection = collection(db, "users");
      const startupDocRef = doc(usersCollection, docId);
      const startupDocSnap = await getDoc(startupDocRef);
      //console.log('send email' + JSON.stringify(currentUser))
      console.log(docId);
      console.log(potentialName);
      if (startupDocSnap.exists()) {
        const payload = {
          sender: {
            name: "PRAIRI",
            email: "invite@prairi.com",
          },
          to: [
            {
              name: potentialName,
              email: potentialEmail,
            },
          ],
          templateId: 46,
          params: {
            STARTUPNAME: startupDocSnap.data().formData.company.company,
            INVESTORNAME: userData.contactName,
            STARTUPID: docId,
            NAME: potentialName.split(" ")[0],
            POTENTIALID: potentialId,
          },
        };
        await axios({
          method: "POST",
          url: "https://api.sendinblue.com/v3/smtp/email",
          headers: {
            Accept: "application/json",
            "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        });
      }
    } catch (error) {
      // Handle error
      console.log(error);
    }
  };

  const sendShareSMS = async (docId, phone, id) => {
    try {
      const messagesCollection = collection(db, "messages");
      let processedPhone;
      phone.charAt(0) === "+"
        ? (processedPhone = phone)
        : (processedPhone = `+${phone}`);
      const dataToStore = {
        to: processedPhone,
        body: `Hi, ${potentialName.split(" ")[0]}. ${
          userData.contactName
        } has sent you a startup's profile on PRAIRI that you might be interested in. https://prairi.com/startup/${docId}/${id}`,
      };
      await addDoc(messagesCollection, dataToStore);
    } catch (error) {
      console.log(error);
    }
  };

  const handleShare = async (docId) => {
    const startupRef = doc(db, "users", docId);
    const startupDoc = await getDoc(startupRef);
    if (potentialPhone === "" && potentialEmail === "") {
      console.log("missing data");
      setShareError("Please provide an email address or phone number");
      return;
    }
    let processedPhone;
    processedPhone =
      potentialPhone.charAt(0) === "+"
        ? (processedPhone = potentialPhone.replace("+", ""))
        : potentialPhone;
    const docRef = await addDoc(collection(db, "potential"), {
      username: potentialName,
      email: potentialEmail,
      phone: processedPhone,
      category: "investor",
      sharedBy: docId,
      name: potentialName.split(" ")[0],
    });
    const id = docRef.id;
    sendShareEmail(docId, id);
    sendShareSMS(docId, potentialPhone, id);
    updateLatestActions(
      userRef,
      `Shared startup ${
        startupDoc.data().formData.company.company
      }with another investor`,
      isMobile
    );
    addToSummary(docId, `Shared with another investor`, docId);
    setTySharePopup(true);
    //console.log("sent?")
  };

  const handleName = (e) => {
    setPotentialName(e.target.value);
  };

  const handleEmail = (e) => {
    setPotentialEmail(e.target.value);
  };

  const handlePhone = (e) => {
    setPotentialPhone(e.target.value);
  };

  //console.log("startups for component", matchesData)
  //
  if (isMobile){
    return (
      <div
        id="newMatch__main"
        style={{
          marginTop: navBarHeight,
          marginBottom: navBarHeight,
          width: "100%",
          minWidth: "366px",
        }}
        className=""
      >
        {!dataFetched && (
          <div className="pt-20">
            <Loading />
          </div>
        )}
        {dataFetched && (
          <div className="flex justify-center">
            <section
              id="newMatch__container"
              className={`
           max-w-[560px] 
  
          m-auto  rounded-md
          min-w-[380px]
         `}
            >
              {matchesData.length > 0 ? (
                <div className="relative mx-2 p-0.5 bg-rainbowBg mt-5 ">
                  <div className="absolute top-[-15px] left-1/2 transform -translate-x-1/2">
                    <RoundedTitle color="black" label="New Match" />
                  </div>
                  <div>
                  <NewStartup
                    startup={matchesData[0]}
                    interested={() => handleInterestedClick()}
                    notInterested={() => handleNotInterestedClick()}
                    archive={() => handleArchivedClick()}
                    title="NEW MATCH"
                    isMobile={isMobile}
                    pitchTracking={() =>
                      pitchTracking(matches[0], docId, isMobile)
                    }
                    state={userData.formData.company.startupstates.length < 49}
                    urlTracking={() => urlTracking(matches[0], docId, isMobile)}
                  />
                  </div>
  
  
                  <div className="w-1/2">
                    <Modal border="yellow" open={archivedPopUp}>
                      <div className=" m-3">
                        <p className="mb-4 ">
                          {" "}
                          OK, we’ll file this match away in your ‘Archive’ folder
                          so you can decide later.
                        </p>
                        <p>
                          TIP: But it’s better if you instead choose ‘Interested’
                          (if you are) since other investors may also be receiving
                          this match.
                        </p>
                      </div>
                      <div className="mt-2 flex justify-between items-center  w-[80%]">
                        <PrairiButton
                          bgColor="regular"
                          border="yellow"
                          size="md"
                          rounded="full"
                          label="Archive"
                          click={() => handleArchived(matchesData[0].id)}
                        />
                        <TextButton
                          label="undo"
                          click={() => setArchivedPopUp(false)}
                        />
                      </div>
                    </Modal>
                  </div>
  
                  <div className="w-1/2">
                    <Modal border="green" open={interestedPopUp}>
                      <div className=" m-3">
                        <p>
                          {" "}
                          Great! We’ll let them know and provide you with their
                          contact info.
                        </p>
                      </div>
                      <div className="flex justify-between items-center  w-[80%]">
                        <PrairiButton
                          bgColor="regular"
                          border="green"
                          size="md"
                          rounded="full"
                          label="OK"
                          click={() => handleInterested(matchesData[0].id)}
                        />
                        <TextButton
                          label="undo"
                          click={() => setInterestedPopUp(false)}
                        />
                      </div>
                    </Modal>
                  </div>
  
                  <div className="w-1/2">
                    <Modal border="red" open={notInterestedPopUp}>
                      <div>
                        <div className="flex justify-center items-center mb-5 mt-2">
                          <FlagLink click={() => setFlagPopUp(true)} />
                        </div>
                        <form>
                          <label for="checkboxes-group">
                            To help this startup so that they may one day be ready
                            to receive investment from you, please provide your
                            feedback.
                            <span className="font-bold">
                              You will remain anonymous.
                            </span>
                          </label>
                          <br />
                          <div className="flex mt-4 items-center">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="no-traction"
                              name="checkboxes-group"
                              value="Not enough traction"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="no-traction"> Not enough traction</label>
                          </div>
                          <br />
                          <div className="flex  items-center">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="team-not-strong"
                              name="checkboxes-group"
                              value="Team not strong enough"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="team-not-strong">
                              {" "}
                              Team not strong enough
                            </label>
                          </div>
                          <br />
                          <div className="flex  items-center">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="bad-timing"
                              name="checkboxes-group"
                              value="Bad timing for this idea or market"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="bad-timing">
                              {" "}
                              Bad timing for this idea or market
                            </label>
                          </div>
                          <br />
                          <div className="flex  items-center">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="competitive-landscape"
                              name="checkboxes-group"
                              value="Landscape is too competitive"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="competitive-landscape">
                              {" "}
                              Landscape is too competitive
                            </label>
                          </div>
                          <br />
                          <div className="flex  items-center">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="higher-capital"
                              name="checkboxes-group"
                              value="Much higher capital would be needed to succeed"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="higher-capital">
                              {" "}
                              Much higher capital would be needed to succeed
                            </label>
                          </div>
                          <br />
                          <div className="flex  items-center">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="other"
                              name="checkboxes-group"
                              value="other"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="other">
                              {" "}
                              Other reason:{" "}
                              <input
                                type="text"
                                className="w-32 ml-3 rounded-lg text-black"
                                id="other-reason"
                                name="other-reason"
                                onChange={handleNotInterestedReasonText}
                              />
                            </label>
                          </div>
                        </form>
                        <div className="my-5">
                          <TextButton
                            label="Don't wish to leave feedback"
                            click={() =>
                              handleNotInterestedNoFeedback(matchesData[0].id)
                            }
                          />
                        </div>
                        <div className="flex justify-between ml-4 items-center  w-[80%]">
                          <PrairiButton
                            bgColor="regular"
                            border="red"
                            size="md"
                            rounded="full"
                            label="Send"
                            click={() =>
                              handleNotInterested(
                                matchesData[0].id,
                                notInterestedReasons,
                                matches
                              )
                            }
                          />
  
                          <div className="ml-3">
                            <TextButton
                              label="undo"
                              click={() => setNotInterestedPopUp(false)}
                            />
                          </div>
                        </div>
                      </div>
                    </Modal>
                    <div className="w-1/2">
                      <Modal
                        border="no-border"
                        open={isFirst && control}
                        size="full"
                      >
                        <div>
                          <img src={TextBubble} className="h-[250px]" />
                        </div>
                        <PrairiButton
                          bgColor="regular"
                          size="md"
                          border="green"
                          rounded="full"
                          label="I understand"
                          click={() => handleFirstOk(docId)}
                        />
                      </Modal>
                    </div>
                    <Modal border="red" open={flagPopUp}>
                      <div className=" m-3">
                        <form>
                          <img
                            src={Flag}
                            className="h-12 mx-auto block mb-5"
                            alt="flag"
                          />
                          <label for="checkboxes-group " className="text-sm">
                            <div className="font-bold ">
                              Report this profile and we'll look into it.
                            </div>
                            PRAIRI personally checks each user before allowing
                            them to join, but it's possible this one slipped past
                            us.
                          </label>
                          <div className="flex flex-col mt-5">
                            <div className="flex mb-4">
                              <input
                                type="radio"
                                id="fake-profile"
                                name="flagReason"
                                value="Fake profile"
                                onChange={handleFlagReason}
                                className="mr-3 w-5"
                              />
                              <label htmlFor="fake-profile">Fake profile</label>
                            </div>
                            <div className="flex mb-4">
                              <input
                                type="radio"
                                id="scammer"
                                name="flagReason"
                                value="Scammer"
                                onChange={handleFlagReason}
                                className="mr-3 w-5"
                              />
                              <label htmlFor="fake-profile">Scammer</label>
                            </div>
                            <div className="flex mb-4">
                              <input
                                type="radio"
                                id="abusive"
                                name="flagReason"
                                value="Abusive to you or others"
                                onChange={handleFlagReason}
                                className="mr-3 w-5"
                              />
                              <label htmlFor="fake-profile">
                                Abusive to you or others
                              </label>
                            </div>
                            <div className="flex mb-4">
                              <input
                                type="radio"
                                id="other"
                                name="flagReason"
                                value="Other"
                                onChange={handleFlagReason}
                                className="mr-3 w-5"
                              />
                              <label htmlFor="other">Other</label>
                              <input
                                type="text"
                                className="w-52 rounded-lg text-black ml-3"
                                id="otherReason"
                                name="otherReason"
                                onChange={(event) =>
                                  setFlagReason(event.target.value)
                                }
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="flex w-[80%] justify-between items-center">
                        <PrairiButton
                          bgColor="regular"
                          border="red"
                          size="md"
                          rounded="full"
                          label="Send"
                          click={() =>
                            handleFlagging(matchesData[0].id, flagReason, matches)
                          }
                        />
  
                        <div className="ml-3">
                          <TextButton
                            label="back"
                            click={() => setFlagPopUp(false)}
                          />
                        </div>
                      </div>
                    </Modal>
                  </div>
                  <Modal border="no-border" open={thankYouPopUp}>
                    <div className="py-5">
                      <img
                        src={ThankYou}
                        alt="Thank you"
                        className="w-[80%] mx-auto mb-6"
                      />
                      <div className="flex place-content-center">
                        <PrairiButton
                          bgColor="regular"
                          border="silver"
                          size="sm"
                          rounded="soft"
                          label="OK"
                          click={() => setThankYouPopUp(false)}
                        />
                      </div>
                    </div>
                  </Modal>
                </div>
              ) : (
                !currentUser && (
                  <div className="mt-20">
                    <PleaseLogin click={redirectToLogin} />
                  </div>
                )
              )}
              {/* {matchesData && matchesData.length===0 && navigate("/thatsall")} */}
              {!dataFetched && <Loading />}
            </section>
          </div>
        )}
      </div>
    );
  }
  else{
    return (
      <div
        id="newMatch__main"
        style={{
          marginTop: navBarHeight,
          marginBottom: navBarHeight,
          width: "100%",
          minWidth: "366px",
        }}
        className=""
      >
        {!dataFetched && (
          <div className="pt-20">
            <Loading />
          </div>
        )}
        {dataFetched && (
          <div className="flex justify-center">
            <section
              id="newMatch__container"
              className={`

  
          m-auto  rounded-md
          min-w-[380px]
         `}
            >
              {matchesData.length > 0 ? (
                <div className="flex ">
                <div className="relative mx-2 p-0.5 bg-rainbowBg mt-5 w-1/3">
                  <div className="absolute top-[-15px] left-1/2 transform -translate-x-1/2">
                    <RoundedTitle color="black" label="New Match" />
                  </div>
                  <div>
                  <NewStartup
                    startup={matchesData[0]}
                    interested={() => handleInterestedClick()}
                    notInterested={() => handleNotInterestedClick()}
                    archive={() => handleArchivedClick()}
                    title="NEW MATCH"
                    isMobile={isMobile}
                    pitchTracking={() =>
                      pitchTracking(matches[0], docId, isMobile)
                    }
                    state={userData.formData.company.startupstates.length < 49}
                    urlTracking={() => urlTracking(matches[0], docId, isMobile)}
                  />
                  </div>
  
  
                  <div className="w-1/2">
                    <Modal border="yellow" open={archivedPopUp}>
                      <div className=" m-3">
                        <p className="mb-4 ">
                          {" "}
                          OK, we’ll file this match away in your ‘Archive’ folder
                          so you can decide later.
                        </p>
                        <p>
                          TIP: But it’s better if you instead choose ‘Interested’
                          (if you are) since other investors may also be receiving
                          this match.
                        </p>
                      </div>
                      <div className="mt-2 flex justify-between items-center  w-[80%]">
                        <PrairiButton
                          bgColor="regular"
                          border="yellow"
                          size="md"
                          rounded="full"
                          label="Archive"
                          click={() => handleArchived(matchesData[0].id)}
                        />
                        <TextButton
                          label="undo"
                          click={() => setArchivedPopUp(false)}
                        />
                      </div>
                    </Modal>
                  </div>
  
                  <div className="w-1/2">
                    <Modal border="green" open={interestedPopUp}>
                      <div className=" m-3">
                        <p>
                          {" "}
                          Great! We’ll let them know and provide you with their
                          contact info.
                        </p>
                      </div>
                      <div className="flex justify-between items-center  w-[80%]">
                        <PrairiButton
                          bgColor="regular"
                          border="green"
                          size="md"
                          rounded="full"
                          label="OK"
                          click={() => handleInterested(matchesData[0].id)}
                        />
                        <TextButton
                          label="undo"
                          click={() => setInterestedPopUp(false)}
                        />
                      </div>
                    </Modal>
                  </div>
  
                  <div className="w-1/2">
                    <Modal border="red" open={notInterestedPopUp}>
                      <div>
                        <div className="flex justify-center items-center mb-5 mt-2">
                          <FlagLink click={() => setFlagPopUp(true)} />
                        </div>
                        <form>
                          <label for="checkboxes-group">
                            To help this startup so that they may one day be ready
                            to receive investment from you, please provide your
                            feedback.
                            <span className="font-bold">
                              You will remain anonymous.
                            </span>
                          </label>
                          <br />
                          <div className="flex mt-4 items-center">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="no-traction"
                              name="checkboxes-group"
                              value="Not enough traction"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="no-traction"> Not enough traction</label>
                          </div>
                          <br />
                          <div className="flex  items-center">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="team-not-strong"
                              name="checkboxes-group"
                              value="Team not strong enough"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="team-not-strong">
                              {" "}
                              Team not strong enough
                            </label>
                          </div>
                          <br />
                          <div className="flex  items-center">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="bad-timing"
                              name="checkboxes-group"
                              value="Bad timing for this idea or market"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="bad-timing">
                              {" "}
                              Bad timing for this idea or market
                            </label>
                          </div>
                          <br />
                          <div className="flex  items-center">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="competitive-landscape"
                              name="checkboxes-group"
                              value="Landscape is too competitive"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="competitive-landscape">
                              {" "}
                              Landscape is too competitive
                            </label>
                          </div>
                          <br />
                          <div className="flex  items-center">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="higher-capital"
                              name="checkboxes-group"
                              value="Much higher capital would be needed to succeed"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="higher-capital">
                              {" "}
                              Much higher capital would be needed to succeed
                            </label>
                          </div>
                          <br />
                          <div className="flex  items-center">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="other"
                              name="checkboxes-group"
                              value="other"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="other">
                              {" "}
                              Other reason:{" "}
                              <input
                                type="text"
                                className="w-32 ml-3 rounded-lg text-black"
                                id="other-reason"
                                name="other-reason"
                                onChange={handleNotInterestedReasonText}
                              />
                            </label>
                          </div>
                        </form>
                        <div className="my-5">
                          <TextButton
                            label="Don't wish to leave feedback"
                            click={() =>
                              handleNotInterestedNoFeedback(matchesData[0].id)
                            }
                          />
                        </div>
                        <div className="flex justify-between ml-4 items-center  w-[80%]">
                          <PrairiButton
                            bgColor="regular"
                            border="red"
                            size="md"
                            rounded="full"
                            label="Send"
                            click={() =>
                              handleNotInterested(
                                matchesData[0].id,
                                notInterestedReasons,
                                matches
                              )
                            }
                          />
  
                          <div className="ml-3">
                            <TextButton
                              label="undo"
                              click={() => setNotInterestedPopUp(false)}
                            />
                          </div>
                        </div>
                      </div>
                    </Modal>
                    <div className="w-1/2">
                      <Modal
                        border="no-border"
                        open={isFirst && control}
                        size="full"
                      >
                        <div>
                          <img src={TextBubble} className="h-[250px]" />
                        </div>
                        <PrairiButton
                          bgColor="regular"
                          size="md"
                          border="green"
                          rounded="full"
                          label="I understand"
                          click={() => handleFirstOk(docId)}
                        />
                      </Modal>
                    </div>
                    <Modal border="red" open={flagPopUp}>
                      <div className=" m-3">
                        <form>
                          <img
                            src={Flag}
                            className="h-12 mx-auto block mb-5"
                            alt="flag"
                          />
                          <label for="checkboxes-group " className="text-sm">
                            <div className="font-bold ">
                              Report this profile and we'll look into it.
                            </div>
                            PRAIRI personally checks each user before allowing
                            them to join, but it's possible this one slipped past
                            us.
                          </label>
                          <div className="flex flex-col mt-5">
                            <div className="flex mb-4">
                              <input
                                type="radio"
                                id="fake-profile"
                                name="flagReason"
                                value="Fake profile"
                                onChange={handleFlagReason}
                                className="mr-3 w-5"
                              />
                              <label htmlFor="fake-profile">Fake profile</label>
                            </div>
                            <div className="flex mb-4">
                              <input
                                type="radio"
                                id="scammer"
                                name="flagReason"
                                value="Scammer"
                                onChange={handleFlagReason}
                                className="mr-3 w-5"
                              />
                              <label htmlFor="fake-profile">Scammer</label>
                            </div>
                            <div className="flex mb-4">
                              <input
                                type="radio"
                                id="abusive"
                                name="flagReason"
                                value="Abusive to you or others"
                                onChange={handleFlagReason}
                                className="mr-3 w-5"
                              />
                              <label htmlFor="fake-profile">
                                Abusive to you or others
                              </label>
                            </div>
                            <div className="flex mb-4">
                              <input
                                type="radio"
                                id="other"
                                name="flagReason"
                                value="Other"
                                onChange={handleFlagReason}
                                className="mr-3 w-5"
                              />
                              <label htmlFor="other">Other</label>
                              <input
                                type="text"
                                className="w-52 rounded-lg text-black ml-3"
                                id="otherReason"
                                name="otherReason"
                                onChange={(event) =>
                                  setFlagReason(event.target.value)
                                }
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="flex w-[80%] justify-between items-center">
                        <PrairiButton
                          bgColor="regular"
                          border="red"
                          size="md"
                          rounded="full"
                          label="Send"
                          click={() =>
                            handleFlagging(matchesData[0].id, flagReason, matches)
                          }
                        />
  
                        <div className="ml-3">
                          <TextButton
                            label="back"
                            click={() => setFlagPopUp(false)}
                          />
                        </div>
                      </div>
                    </Modal>
                  </div>
                  <Modal border="no-border" open={thankYouPopUp}>
                    <div className="py-5">
                      <img
                        src={ThankYou}
                        alt="Thank you"
                        className="w-[80%] mx-auto mb-6"
                      />
                      <div className="flex place-content-center">
                        <PrairiButton
                          bgColor="regular"
                          border="silver"
                          size="sm"
                          rounded="soft"
                          label="OK"
                          click={() => setThankYouPopUp(false)}
                        />
                      </div>
                    </div>
                  </Modal>
                </div>
                <div className="w-2/3 pt-5">
                {dataFetched && matchesData && <Pdf src={matchesData[0].formData.company.deck} h='600px'/>}
                </div>
                </div>
              ) : (
                !currentUser && (
                  <div className="mt-20">
                    <PleaseLogin click={redirectToLogin} />
                  </div>
                )
              )}
              {/* {matchesData && matchesData.length===0 && navigate("/thatsall")} */}
              {!dataFetched && <Loading />}
            </section>
          </div>
        )}
      </div>
    );
  }
  
};

export default NewMatch;